<template>
  <div class="sLogin">
    <h1>Login to <span>Shepherd</span></h1>
    <button
      class="googleLogin"
      @click="socialLogin"
    />
  </div>
</template>

<script>
import firebase from 'firebase';
import { auth } from '../../firestore';

export default {
  name: 'Login',
  methods: {
    socialLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider).then((response) => {
        const domain = response.user.email.replace(/.*@/, '');
        if (domain !== 'fundamentalmedia.com') {
          this.$notify({
            type: 'error',
            title: 'Invalid Email Address',
            text: 'Your account is not allowed to access this system',
          });
          auth.signOut();
        } else {
          this.$router.push({ name: 'home' });
        }
      }).catch(() => {
        // console.warn(error.message);
        this.$notify({
          type: 'error',
          title: 'Firebase Auth Error',
        });
      });
    },
  },
};
</script>

<style scoped lang="sass">
@import "@/scss/shepherd-vars.scss"
.sLogin
  width: 100vw
  height: 100vh
  background: $colorGrey2
  text-align: center
  padding-top: 100px
  h1
    color: $colorOrange
    font-family: $fontFamily
    margin-bottom: 50px
    span
      font-family: $fontLogo
  .googleLogin
    background: url('../../assets/googleSignin/btn_google_signin_light_normal_web@2x.png')
    width: 382px
    height: 92px
    border: 0
    &:hover
      background: url('../../assets/googleSignin/btn_google_signin_light_pressed_web@2x.png')
</style>
